import { ISubscribable } from "app/common/EventAggregator";

import { IWidgetInitializationStrategy } from "app/models/IWidgetInitializationStrategy";
import { IShoppingServiceProxy } from "app/models/ShoppingServiceProxy";
import { WidgetDefinition } from "app/models/WidgetDefinition";

export class GlobalApi  {
    private readonly isInitializedInternal: boolean;
    
    eventAggregator: ISubscribable;
    serverSideApi: IShoppingServiceProxy;

    constructor(isInitilized = false, eventAggregator: ISubscribable = null, serverSideApi: IShoppingServiceProxy = null) {
        this.isInitializedInternal = isInitilized;
        this.eventAggregator = eventAggregator;
        this.serverSideApi = serverSideApi;   
    }

    initializer: IWidgetInitializationStrategy;
    isInitialized(): boolean {
        return this.isInitializedInternal;
    };

    public initializeWidget(widgetDefinition: WidgetDefinition) : void {
        this.initializer.initialize(widgetDefinition);
    };
}