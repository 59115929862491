import { WidgetDefinition } from "app/models/WidgetDefinition";
import { IWidgetInitializationStrategy } from "app/models/IWidgetInitializationStrategy";

export class LazyWidgetInitializationStrategy implements IWidgetInitializationStrategy {

    widgets: WidgetDefinition[];

    constructor() {
        this.widgets = new Array<WidgetDefinition>();
    }

    initialize(widget: WidgetDefinition) {
        if (widget == null) {
            throw new Error("Argument null exception. Widget need to be provided");
        }

        this.widgets.push(widget);
    }
}
