import { ApiResolver } from "app/common/ApiResolver";
import { LazyWidgetInitializationStrategy } from "app/models/LazyWidgetInitializationStrategy";
import { WidgetDefinitionBuilder } from "app/models/WidgetDefinitionBuilder";
import { CurrentScriptResolver } from "app/common/CurrentScriptResolver";

import { TrackerResolver } from "app/common/tracking/GlobalTracker";
module SKF.App.Widget {

	export class Init {

		constructor() {
		    var api = ApiResolver.ensureAvailable();
            api.initializer = api.initializer || new LazyWidgetInitializationStrategy();
		}

        embed() {
            var api = ApiResolver.ensureAvailable();       
            var currentScript = CurrentScriptResolver.resolve();
            var definition = new WidgetDefinitionBuilder().build(currentScript);
            var container = document.createElement('div');
            container.id = definition.containerId;

            currentScript.parentNode.replaceChild(container, currentScript);
            api.initializer.initialize(definition);
        }
	}
}

new SKF.App.Widget.Init().embed();