import { WidgetDefinition } from "app/models/WidgetDefinition";
import { WidgetType } from "app/models/WidgetType";

export class CartWidgetDefinition extends WidgetDefinition {
    constructor(id: string, node: HTMLElement, type: WidgetType, continueShoppingUrl: string) {
        super(id, node, type);
        this.continueShoppingUrl = continueShoppingUrl;
    }

    continueShoppingUrl: string;
}