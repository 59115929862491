import { WidgetType } from "app/models/WidgetType";

export class WidgetDefinition {

	constructor(id: string, node = null,  type: WidgetType) {
		if ((id == null || id === "") && node == null) {
            throw new Error("Argument exception. Container need to be provided");
        }

        if (type == null) {
            throw new Error("Argument exception. Type need to be provided");
        }

        this.containerId = id;
		this.widgetType = type;
		this.node = node;
	}
	node: HTMLElement;
    containerId: string;
    widgetType: WidgetType;
}
