import { UniqueIdentifierGenerator } from "app/common/UniqueIdentifierGenerator";
import { WidgetDefinition } from "app/models/WidgetDefinition";
import { ProductWidgetDefinition } from "app/models/ProductWidgetDefinition";
import { CartWidgetDefinition } from "app/models/CartWidgetDefinition";
import { MyPageWidgetDefinition } from "app/models/MyPageWidgetDefinition";
import { WidgetType } from "app/models/WidgetType";

export class WidgetDefinitionBuilder {
    build(element): WidgetDefinition {
        var widgetType = element.getAttribute("data-type");
        var id = widgetType + "_" + UniqueIdentifierGenerator.generate();


        if (widgetType === WidgetType.product) {
            return new ProductWidgetDefinition(id, null, widgetType, element.getAttribute("data-article-number"), element.getAttribute("data-reseller-url"));
        }

        if (widgetType === WidgetType.cart) {
            return new CartWidgetDefinition(id, null, widgetType, element.getAttribute("data-shop-url"));
        }
	    if (widgetType === WidgetType.mypage) {
			return new MyPageWidgetDefinition(id, null, widgetType);
	    }

        throw new Error("Invalid operation exception when trying to build widet defintion. Provided widget type '" +
            widgetType +
            "' is not supported");
    }
}