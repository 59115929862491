import { WidgetDefinition } from "app/models/WidgetDefinition";
import { WidgetType } from "app/models/WidgetType";

export class ProductWidgetDefinition extends WidgetDefinition {

    constructor(id: string, node: HTMLElement, type: WidgetType, articleNumber: string, resellerUrl: string) {
        super(id, node, type);

        if (articleNumber == null || articleNumber === "") {
            throw new Error("Argument exception. Article number need to be provided");
        }

        if (resellerUrl == null || resellerUrl === "") {
            throw new Error("Argument exception. Reseler url need to be provided");
        }

        this.articleNumber = articleNumber;
        this.resellerUrl = resellerUrl;
    }

    articleNumber: string;
    resellerUrl: string;
}