import { GlobalApi } from "app/models/GlobalApi";
import { WidgetsConfiguration } from "types/Models";
declare global {
    interface Window { SKFVSM: any; }
    interface Window { LitiumContext: any; }
}

export class ApiResolver {

    static ensureAvailable(): GlobalApi {

	    window.SKFVSM = window.SKFVSM || {};
        window.SKFVSM.API = window.SKFVSM.API || new GlobalApi();
     

        return window.SKFVSM.API;
    }

    static initialize(api: GlobalApi) {
        window.SKFVSM = window.SKFVSM || {};
        window.SKFVSM.API = api;
      
;
    }
}