
import "current-script-polyfill"

declare global {

    interface Document { _currentScript: any; }
}

export class CurrentScriptResolver {
	static resolve()  {

	    var currentScript = null;
	    if (document._currentScript) {
	        currentScript = document._currentScript;
	    } else {
	        currentScript = document.currentScript;
	    }

	    return currentScript;
	}
}

